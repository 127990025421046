.Contenedor {
  width: 75%;
  margin: auto;
}
.ReporteHome {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 2px 5px;
  text-align: center;
}

.item {
  padding: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.menu-boton {
  background-color: whitesmoke;
  border-color: navy;
  border-width: 0px 0px 1px 0px;
}
.menu-boton:hover {
  cursor: pointer;
  background-color: lightgray;
}

.menu-activo {
  border-color: red;
  color: red;
}

.cuatro {
  grid-column: 1 / span 4;
}

.dos1 {
  grid-column: 1 / span 2;
}
.dos2 {
  grid-column: 3 / span 2;
}

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.prev {
  left: 0;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.default {
  display: block;
}

.cartita {
  background-color: whitesmoke;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 15px;
  width: 100%;
}

.cartita > button {
  position: absolute;
}

.editarReporte {
  text-align: center;
  vertical-align: middle;
}

.mini-carrusel {
  overflow: auto;
  white-space: nowrap;
  margin: auto;
}

.mini-carrusel-imagen {
  display: inline-block;
  padding: 5px;
}

/* Caption text */
.text-carousel {
  background-color: rgba(196, 194, 194, 0.9);
  color: black;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  background-color: rgba(196, 194, 194, 0.8);
  color: black;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 480px) {
  .Contenedor {
    width: 100%;
  }
  .slideshow-container {
    min-height: 400px;
  }
}
