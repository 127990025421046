.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.scrollmenu {
  background-color: #f44336;
  overflow: auto;
  white-space: nowrap;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}

.Bloque {
  width: 100vw;
  max-width: 640px;
  height: 100vh;
  margin: 0;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  background-color: crimson;
  display: block;
}

.Titulo {
  background-color: darkgrey;
  width: 80vw;
  max-width: 512px;
  height: 10vh;
  position: absolute;
  left: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 5vw;
}

.Menu {
  width: 80vw;
  max-width: 512px;
  position: absolute;
  top: 11vh;
  left: 10vw;
  overflow-x: scroll;
}

.Cuerpo {
  background-color: whitesmoke;
  width: 80vw;
  max-width: 512px;
  height: 84vh;
  position: absolute;
  left: 10vw;
  margin-top: 12vh;
  overflow-y: scroll;
}

.Boton {
  background-color: thistle;
  display: inline-block;
  width: 32.5vw;
  max-width: 208px;
  height: 25vh;
  margin-left: 5vw;
  margin-top: 2.25vh;
}

.Barra {
  background-color: rgb(105, 99, 105);
  height: 7vh;
  position: absolute;
  left: 10vw;
  bottom: 2vh;
  display: flex;
  justify-content: center;
}

.Ancho {
  width: 80vw;
  max-width: 512px;
}

.Botoncito {
  width: 16vw;
  max-width: 102.4px;
  flex: 1;
  color: rgba(255, 255, 255, 0.7);
  padding: 6px 12px 8px;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media screen and (min-width: 640px) {
  .Bloque {
    padding-left: 12.8px;
    background-color: teal;
  }
  .Titulo {
    position: absolute;
    left: 64px;
  }
  .Menu {
    position: absolute;
    left: 64px;
  }
  .Cuerpo {
    position: absolute;
    left: 64px;
  }
  .Barra {
    position: absolute;
    left: 64px;
  }
  .Boton {
    margin-left: 32px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.pegado {
  display: flex;
  flex-wrap: wrap;
}

.izq {
  margin-right: 5px;
}
.shrink {
  display: flex;
  height: auto;
  font-size: 1.7vw;
}
.App-link {
  color: #61dafb;
}
.scrolling-wrapper {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  vertical-align: top;
}

.fechas {
  display: none;
  align-items: center;
  vertical-align: middle;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.selectFechas {
  position: absolute;
  top: 50%;
  left: 25%;
  color: white;
  align-items: center;
  vertical-align: middle;
  padding: 2.5%;
}

.card {
  flex: 0 0 auto;
}

.dropbtn {
  background-color: rgb(155, 156, 15, 0.3);
  color: grey;
  width: 100%;
  padding: 2%;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.dropdown {
  width: 23%;
  margin: 1%;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(249, 249, 249, 0.8);
  width: 100%;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a,
button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a,
button:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
  color: white;
}

.rubros {
  width: 99%;
  margin: 0.5%;
  padding: 1%;
}
.nombres {
  background-color: white;
  overflow: hidden;
}
.texto {
  padding-left: 1%;
  width: 100%;
  height: 100%;
}

.iconos {
  display: inline-block;
  width: 40%;
  height: 100%;
  background-color: antiquewhite;
  overflow: hidden;
}

.nombres:hover + .iconos {
  background-color: rgba(255, 255, 255, 0.3);
  width: 20%;
}
.categoria {
  width: 100%;
}
.categoria:hover {
  cursor: pointer;
}

.bloqArriba {
  width: 96%;
  height: 48%;
  margin: 2%;
  padding: 1%;
  background-color: rgba(255, 255, 255, 0.7);
}
.bloqAbajo {
  width: 96%;
  height: 48%;
  margin: 2%;
  padding: 1%;
  background-color: rgba(255, 255, 255, 0.7);
  overflow: auto;
}

.datosUsuarios {
  width: 100%;
  height: 80%;
  overflow: auto;
}

.cartaUsuario {
  width: 100%;
  margin: 1% 0% 1% 0%;
  padding: 1%;
  overflow: hidden;
  display: inline-block;
}
.reportes {
  display: none;
  position: absolute;
  right: 0;
  background-color: rgba(249, 249, 249, 0.8);
  min-width: 30vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.reportes a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.cartaUsuario:hover {
  background-color: lightgray;
}
.cartaUsuario:hover .reportes {
  display: block;
}

.reportes a:hover {
  background-color: lightgrey;
}

table.solicitudes,
th,
td {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid darkgrey;
}

tr:hover {
  background-color: lightgray;
}

tr:first-child:hover {
  background-color: whitesmoke;
}
td:hover {
  cursor: pointer;
}

.desv {
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  overflow: auto;
  padding: 1%;
}
.devTitle {
  height: 10%;
  width: 100%;
  background-color: white;
}
.status {
  display: inline-block;
  border-radius: 5px;
  border: solid 0.5px lightgrey;
  margin: 5px;
  width: 30%;
  text-align: center;
  background-color: whitesmoke;
}

.status:hover {
  cursor: pointer;
  opacity: 0.75 !important;
}

.desvContent {
  margin-top: 5px;
  height: 90%;
  background-color: white;
  padding: 5%;
  overflow: auto;
}

.desvDetalle {
  width: 100%;
  background-color: whitesmoke;
  margin: 10px 0 10px 0;
  padding: 5px;
  border-bottom: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.desvDetalle:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.detalle {
  width: 50%;
  height: 100%;
  padding: 10px;
  float: left;
  overflow: auto;
}

.desvImg {
  width: 100%;
  padding: 15px;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.desvImg > img {
  width: 100%;
  height: 100%;
}

.detalleContent {
  background-color: whitesmoke;
  width: 100%;
  float: left;
  height: 100%;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.corral {
  background-color: brown;
}

.rojo {
  background-color: indianred;
}

.pap,
.pac {
  margin: 0 5px 0 5px;
  float: left;
  background-color: white;
  width: 96%;
  height: 100%;
  padding: 15px;
  overflow: auto;
}

.grande {
  border-radius: 50% 50% 50% 50%;
  background-color: #f1f1f1;
  font-size: 48px;
  position: absolute;
  top: 50%;
  left: 0%;
}
.regular {
  display: inline-block;
  margin: 2%;
  height: 100%;
}
.grande:hover,
.regular:hover {
  cursor: pointer;
}

input[type="text"],
select {
  width: 100%;
  padding: 5px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.PACname {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
}

textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 5px;
  margin: 8px 0;
  width: 100%;
  height: 150px;
}
label {
  font-weight: bold;
}

.submit {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.deshabilitado {
  background-color: gray;
  color: white;
  cursor: default;
}

.invisible {
  display: none;
}

.submit:hover {
  background-color: #45a049;
}

.deshabilitado:hover {
  background-color: gray;
}

.nuevo-reporte {
  font-size: 48px;
  color: green;
  position: absolute;
  bottom: 5%;
  right: 5%;
  z-index: 1;
}

.nuevo-reporte-caption {
  width: auto;
  position: absolute;
  bottom: 2%;
  right: 5%;
  z-index: 1;
  display: none;
}

.nuevo-reporte:hover ~ .nuevo-reporte-caption {
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.nuevo-reporte:hover {
  color: green;
  font-weight: bold;
  cursor: pointer;
  text-shadow: 2px 2px 2px 2px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*HOME*/
button {
  cursor: pointer;
}

.homeFilter {
  background-color: rgba(0, 0, 0, 1);
  color: white;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: None;
  z-index: 0;
}

.filterContainer {
  background-color: rgba(0, 0, 0, 1);
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 2px 5px;
  text-align: center;
  width: 100%;
}

.filterClose {
  background-color: rgba(0, 0, 0, 1);
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  margin: 2px;
}

.filterTab {
  color: white;
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  gap: 2px 5px;
  text-align: center;
  border-right: 2px solid whitesmoke;
}

.selected {
  background-color: #3e8e41;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  float: right;
  padding: 10px;
}

.filterTitle {
  width: 100%;
  height: 50px;
  padding: 2px;
  font-size: larger;
  font-weight: bolder;
  border-bottom: 1px dashed whitesmoke;
}

.filterItem {
  color: whitesmoke;
  height: 50px;
  width: 100%;
  padding: 15px;
}

.filterItem:hover {
  cursor: pointer;
  background-color: #ccc;
  color: #282c34;
}

.filtros {
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  gap: 2px 5px;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
}

.filtro {
  width: 150px;
  padding: 15px;
  background-color: rgb(250, 235, 215, 0.5);
  border-radius: 15px;
}

.homeContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 2px 5px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  max-width: 680px;
  margin: auto;
}

.homeTitle {
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  text-align: left;
  margin: 2px;
  font-size: large;
  font-weight: bolder;
}

.homeResults {
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  height: 25vh;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  gap: 2px 5px;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.homeQSEB {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  gap: 2px 5px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  height: 100%;
}

.QSEB {
  margin: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 25% auto;
  background-color: #ccc;
  height: 80%;
  width: 75%;
  border-radius: 20px;
}

.QSEBname {
  margin: auto;
  font-weight: bold;
  font-size: medium;
}

.QSEBresult {
  margin: auto;
  font-size: xx-large;
}

.homeDetails {
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.homeDetailsTipo {
  display: grid;
  grid-template-columns: 100%;
  gap: 2px 5px;
  margin: 5px;
  text-align: left;
  vertical-align: middle;
  align-items: center;
}

.homeCumplimiento {
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
}

.progreso {
  margin: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  vertical-align: middle;
  align-items: center;
}

.progresoCard {
  width: 70%;
  display: grid;
  margin: 10px;
  min-height: 125px;
  background-color: rgba(247, 238, 225, 0.24);
  border-radius: 20px;
  grid-template-columns: 100%;
  grid-template-rows: 25% auto;
  gap: 2px 5px;
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
}

/*Mis Requerimientos*/

.reqContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2px 5px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}

.division {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.tres {
  grid-column: 1 / span 3;
}
.dos {
  grid-column: 1 / span 2;
}
.dosdos {
  grid-column: 2 / span 2;
}

.usuariobtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.usuariobtn:hover,
.usuariobtn:focus {
  background-color: #3e8e41;
}

#userSearch {
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#userSearch:focus {
  outline: 3px solid #ddd;
}

.usuarios {
  position: relative;
  display: inline-block;
}

.usuarios-content {
  display: block;
  background-color: #f6f6f6;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.usuarios-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.usuarios a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

#contenedor {
  width: 120px;
  height: 120px;
  position: relative;
  align-items: center;
  text-align: center;
}

#subtitulo {
  position: absolute;
  top: 45%;
  width: 100%;
  align-items: center;
  text-align: center;
}

#metro {
  width: 100%;
  height: 100%;
  transform: rotate(270deg);
}

.acto {
  margin: auto;
  max-width: 480px;
  display: grid;
  grid-template-columns: 100%;
  gap: 2px 5px;
  place-items: center;
}

.acto :is(input, select) {
  width: 100%;
  border-radius: 6px;
  height: 36px;
  border: solid 1px black;
  padding: 6px;
}

.acto-lista {
  border-left: black solid 1px;
  border-right: black solid 1px;
  background-color: white;
}

.acto-lista-search {
  display: block;
  padding: 2px;
}
.acto-lista-data {
  max-height: 200px;
  overflow-y: auto;
  display: none;
}

.acto-lista-search:focus ~ .acto-lista-data {
  display: block;
}

.acto-lista-item {
  padding: 1%;
  height: 50px;
  cursor: pointer;
}

.acto-lista-item:hover {
  background-color: rgb(204, 204, 204, 0.5);
}

.listaPersonas {
  padding: 1%;
  display: inline-block;
}

.listaPersonas:is(div) {
  width: 100%;
  margin: 1%;
}

.eliminar {
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid;
  position: absolute;
  right: 1%;
  cursor: pointer;
  color: red;
  font-size: 24px;
  background-color: white;
}

.eliminar:hover {
  border: 3px solid;
  font-size: large;
}

.imgPrev:hover {
  width: 100%;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-file-input {
  display: none;
}

.acto-item {
  padding: 5%;
  width: 480px;
}

.acto-item-button {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  border-radius: 12px;
  border: none;
  background-color: rgb(4, 170, 109);
  color: white;
}

.acto-item-button:hover {
  color: white;
  background-color: #04aa6d;
  font-weight: bolder;
}

.acto-item-button:disabled {
  background-color: rgb(119, 119, 119);
  cursor: not-allowed;
}

#filtros {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 40%;
  gap: 5%;
  place-content: center;
}

#resultados {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  border-bottom: dashed grey 1px;
  padding-bottom: 10px;
  column-gap: 2.5%;
}

#qseb {
  margin-top: 10px;
  width: 100%;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 120px 120px 120px 120px;
}

#detalles {
  margin-top: 10px;
  width: 95%;
  display: grid;
  grid-template-columns: 100%;
  row-gap: 10px;
  place-content: center;
}

#progresos {
  margin-top: 10px;
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  place-content: center;
}

#otros {
  width: 95%;
  margin: auto;
  display: grid;
  grid-template-columns: 100%;
  place-content: center;
}

#actos {
  display: grid;
  grid-template-columns: 50% 50%;
  place-content: center;
  text-align: center;
  height: 150px;
  border-left: dotted 1px grey;
  border-bottom: dotted 1px grey;
  padding: 10px;
}

#planes {
  height: 250px;
  border-left: dotted 1px grey;
  padding: 10px;
}

#color {
  background-color: rgb(240, 140, 140);
}

#cuadrado {
  margin: auto;
  width: 50%;
  height: 80px;
  border: #777 1px solid;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  padding-top: 30px;
}

@media screen and (max-width: 1080px) {
  .acto-item {
    max-width: 300px;
  }
  #resultados {
    grid-template-columns: 100%;
    align-items: center;
  }
  #progresos {
    grid-template-columns: 100%;
  }
  #otros {
    width: 100%;
  }

  #planes,
  #actos {
    border-left: none;
  }

  #qseb {
    grid-template-columns: 120px 120px;
    column-gap: 20px;
    width: auto;
    margin: auto;
  }
  .desvDetalle {
    border-radius: 5px;
    border: rgb(105, 99, 105) 1px solid;
    font-size: large;
  }
  .status {
    display: block;
    width: 75%;
  }
  .devTitle {
    height: auto;
  }
  .regular {
    margin: 1%;
  }
  .devTitle > h2 {
    font-size: large;
  }
  .desvContent {
    height: 95%;
  }
  .detalle {
    width: 100%;
    height: auto;
    overflow: auto;
  }
  .desvImg {
    float: none;
    width: 100%;
    height: auto;
  }
  .detalleContent {
    float: none;
    width: 100%;
    height: auto;
  }
  .pac,
  .pap {
    width: 100%;
    padding: 10px;
    margin: 0;
  }
  .homeResults {
    grid-template-columns: 50% 50%;
    height: 250px;
  }
  .QSEBname {
    font-size: small;
  }
  .homeDetails {
    grid-template-columns: 100%;
  }
  .filterContainer {
    grid-template-columns: 100%;
  }
  .filterClose {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .filterTitle {
    border-top: 1px dashed whitesmoke;
  }
  .filtros {
    grid-template-columns: 50% 50%;
  }
  .filtroName {
    font-size: small;
  }
  .progreso {
    grid-template-columns: auto;
  }
}
